<template mt-5 align-center>

<v-layout mt-5 align-start>

    <v-flex mt-5>
  <v-data-table
    :headers="headers"
    :items="ArrayUsuarios"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
           <v-layout row justify-space-between mt-5>
        <v-flex  xs4 sm2 md2 lg2>
        <v-toolbar-title>Usuarios</v-toolbar-title>
        </v-flex>

         
        

        <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="mdi-magnify" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>


        <v-btn dark color="indigo" class="mx-5" @click="listar()"><v-icon>autorenew</v-icon></v-btn>



        <v-spacer></v-spacer>
        <v-flex xs3 sm4 md3 lg3>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="accent"
              dark
           
              v-bind="attrs"
              v-on="on"
            >Nuevo</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.nombre_usuario" label="nombre_usuario"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                     <v-select
                              v-model="editedItem.rol"
                              :items="rolesArray"
                              label="rol"
                          ></v-select>

                  </v-col>


                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.usr" label="usr"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.pwd" label="pwd"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">

                  <v-select
                              v-model="editedItem.id_usr_empresa"
                              :items="ArrayEmpresas"
                              label="Empresa"
                          ></v-select>
                  
                  
                  </v-col>


                   <v-col cols="12" sm="6" md="4">

                    <input type="radio" :value="1" v-model="editedItem.estado"> Activo
                    <input type="radio" :value="0" v-model="editedItem.estado"> Inactivo
 
                  </v-col>

               

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-flex>
           </v-layout>
      </v-toolbar>
    </template>


 <template v-slot:[`item.estado`]="{ item }">
        <v-icon>
           {{ item.estado==1 ? "done" : "label_off" }}
        </v-icon>
  </template>


<template v-slot:[`item.id_usr_empresa`]="{ item }">
        <span>
           {{ item.id_usr_empresa.nombre_empresa }}
        </span>
  </template>

  <template v-slot:[`item.creado`]="{ item }">
        <span>{{ FormatearFechaHora(item.creado)}}</span>
                 
     </template>



  <template v-slot:[`item.pwd`]="{ item }">

    <v-icon>vpn_key</v-icon>
        <span>
        {{item.pwd}}
        </span>
  </template>


    <template v-slot:[`item.actions`]="{ item }">
      <v-icon color="green"
        small
        class="mr-2"
        @click="editItem(item)"
      >
        edit
      </v-icon>
      <v-icon color="red"
        small
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>
  </v-data-table>

    </v-flex>

</v-layout>
</template>

<script>


import axios from 'axios';

import { mapState } from "vuex";

import { mapMutations } from "vuex";


  export default {
    data: () => ({
        search:'',
        mensaje:'',
        ArrayUsuarios:[],
        ArrayEmpresas:[],
        rolesArray:[{text:'USER', value:'USER'},{text:'ADMIN', value:'ADMIN'}],
      dialog: false,
      headers: [
        { text: 'Nombre usuario', value: 'nombre_usuario' },
        { text: 'Rol', value: 'rol' },
        { text: 'Usr', value: 'usr' },
        { text: 'Pwd', value: 'pwd' },
        { text: 'Empresa', value: 'id_usr_empresa' },
        { text: 'Activo', value: 'estado' },
         { text: 'Creado', value: 'creado' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        nombre_usuario: '',
        rol: '',
        usr: '',
        pwd: '',
        id_usr_empresa: '',
        estado:1
      },
      defaultItem: {
        nombre_usuario: '',
        rol: '',
        usr: '',
        pwd: '',
        id_usr_empresa: '',
        estado:1
      },
    }),

    computed: {

      ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
       this.$root.$refs.Home.detenerTimer(); 
       this.listar();
       this.listarEmpresas();
    },

    methods: {

        ...mapMutations(['mostrarLoading','ocultarLoading']),

      listar () {

         let config={headers:{token:this.token}};
       this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;
          axios.get('usuarios',
           config
          ).then(function(response){
             console.log(response);
             me.ArrayUsuarios=response.data;
             me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

        
      },

       listarEmpresas () {

       let config={headers:{token:this.token}};
       this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaEmpresas=[];

          axios.get('empresas', 
            config
          ).then(function(response){
             console.log(response);
             RespuestaEmpresas=response.data;

             RespuestaEmpresas.map(function(x){
                  me.ArrayEmpresas.push({text:x.nombre_empresa, value:x._id})
                });

              me.ocultarLoading();
              
          }).catch(function(error){
            console.log(error);
          });

         


      },


      editItem (item) {
        this.editedIndex = this.ArrayUsuarios.indexOf(item)
        this.editedItem = Object.assign({}, item)

          //asignamos en selec la opcion que corresponde en edicion
          this.editedItem.id_usr_empresa = item.id_usr_empresa._id;
     
        this.dialog = true
      },

      deleteItem (item) {
        const index = this.ArrayUsuarios.indexOf(item)

        let r= confirm('Deseas deshabilitar usuario?');

           if(r==true){

                   let config={headers:{token:this.token}};
             
                    let id=item._id;

                    axios.put(`usuario/${id}`,{ '_id':item._id,'estado':0},
                            config

                        ).then(response=>{
                      
                        console.log(response);
                        this.ArrayUsuarios.splice(index, 1);
                        this.listar();

                  }).catch(e=>{
                        console.log(e)
                  });
             
           }

      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {


var objetoenviar= { 
                      '_id':this.editedItem._id,
                      'nombre_usuario':this.editedItem.nombre_usuario,
                      'rol':this.editedItem.rol,
                      'usr':this.editedItem.usr,
                      'pwd':this.editedItem.pwd,
                      'id_empresa':this.editedItem.id_usr_empresa,
                      'estado':this.editedItem.estado
                       };


                       console.log(objetoenviar);



           //actualizar datos de item
                      let config={headers:{token:this.token}};
             
                    let id=this.editedItem._id;

                    axios.put(`usuario/${id}`,
                      { 
                      '_id':this.editedItem._id,
                      'nombre_usuario':this.editedItem.nombre_usuario,
                      'rol':this.editedItem.rol,
                      'usr':this.editedItem.usr,
                      'pwd':this.editedItem.pwd,
                      'id_empresa':this.editedItem.id_usr_empresa,
                      'estado':this.editedItem.estado
                       },
                       config

                        ).then(response=>{
                      
                        console.log(response);
                     
                        this.listar();

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });




        } else {

              //guardamos datos

              console.log('empresa:'+this.editedItem.id_usr_empresa);


              let config={headers:{token:this.token}};

          axios.post('nuevo-usuario',
                     { 
                      'nombre_usuario':this.editedItem.nombre_usuario,
                      'rol':this.editedItem.rol,
                      'usr':this.editedItem.usr,
                      'pwd':this.editedItem.pwd,
                      'id_empresa':this.editedItem.id_usr_empresa,
                      'estado':this.editedItem.estado
                       },
                   config
          ).then(response=>{
           
            this.close();
            console.log(response);
             this.listar();

          }).catch(e=>{
                console.log(e)
              
          });



        }
        this.close()
      },

       FormatearFechaHora(FechaHoraInicial){
                       var pFechaHoraInicial= new Date(FechaHoraInicial).toISOString().substring(0,10);
                       var textoHora=FechaHoraInicial.split("T")[1];
                        textoHora= textoHora.substring(0,textoHora.length - 5);
                        return(pFechaHoraInicial+' '+textoHora);
                    },

    },
  }
</script>