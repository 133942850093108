<template>

<v-layout class="mt-3">

     <v-row >

        <v-data-table
            :headers="headers"
            :items="arrayAlertas"
            sort-by="creado"
            class="elevation-1 mx-2"
            :search="search"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>
                  <v-icon dark color="indigo" class="mx-1">campaign</v-icon>
                  Alertas
                  
                  </v-toolbar-title>

                  <v-switch v-model="switch1" small  :label="LabelSwitch" class="mt-6 mx-2"></v-switch>


                    <v-btn class="mx-1"  v-show="switch1==false" color="primary" @click="dialogHistorial=true">
                        <v-icon dark>
                        today
                        </v-icon>
                    </v-btn>
                  


                    <vue-excel-xlsx v-show="arrayAlertas.length >0" class="ml-4"
                        :data="arrayAlertas"
                        :columns="column_excel"
                        :file-name="'alertas'"
                        :sheet-name="'hoja1'"
                        :file-type="'xlsx'">
                        <v-btn color="success">
                            <v-icon dark>get_app</v-icon>
                        </v-btn>
                    
            </vue-excel-xlsx>



                <v-divider
                class="mx-4"
                inset
                vertical
                ></v-divider>


                 <v-text-field
                                  v-model="search"
                                  append-icon="mdi-magnify"
                                  label="buscar"
                                  single-line
                                  hide-details
                                ></v-text-field>


                    <v-btn @click="listar()" color="success" class="mx-3">
                      <v-icon dark>sync</v-icon>
                      </v-btn>

                <v-spacer></v-spacer>

                <v-dialog v-model="dialogTabla" max-width="750px">

                <v-card>

                     <v-toolbar color="primary">
                        <v-toolbar-title class="white--text">  {{ formTitle }}
                          
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                          <v-icon dark @click="dialogTabla=false">close</v-icon>
                      
                      </v-toolbar>



                    <v-card-text>
                    <v-container>
                        <v-row>

                        <v-col cols="12" sm="10" md="10">
                          
                            <v-textarea
                              rows="1"
                              label="Observacion"
                              hint="Observacion"
                              v-model="editedItem.observacion"
                            ></v-textarea>

                        </v-col>



                        <v-col cols="12">

                           <v-checkbox
                           v-model="checkboxAll"
                            label="Seleccionar todos"
                            @change="CheckboxTodos()"
                         ></v-checkbox>



                            <v-data-table
                              :headers="headersSelected"
                              :items="itemSelected"
                             
                             
                              class="elevation-1"
                            >

                                   <template v-slot:[`item.vehiculo`]="{ item }">
                                    <span>{{ item.id_vehiculo.nombre }}</span>            
                                    </template>

                                    <template v-slot:[`item.placa`]="{ item }">
                                    <span>{{ item.id_vehiculo.placa }}</span>            
                                    </template>

                                    <template v-slot:[`item.flota`]="{ item }">
                                    <span>{{ item.id_vehiculo.flota }}</span>            
                                    </template>


                                    <template v-slot:[`item.conductor`]="{ item }">
                                    <span>{{ item.id_conductor.nombre_conductor }}</span>            
                                    </template>


                                    
                                    <template v-slot:[`item.fecha`]="{ item }">
                                      <span>{{ getDate(item.fecha) }}</span>
                                    </template>

                              
                            </v-data-table>

                        </v-col>
                        
                        </v-row>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogTabla=false">
                        Cancelar
                    </v-btn>

                    <v-btn color="success" dark @click="save">
                        Guardar
                    </v-btn>

                    </v-card-actions>
                </v-card>
                </v-dialog>


             
                  



                <v-dialog v-model="dialogHistorial" persistent max-width="280">
                <v-card>
                    <v-toolbar flat color="primary" class="text-center">
                    <v-toolbar-title class="white--text  text-center"><strong>Historial</strong></v-toolbar-title>
                </v-toolbar>

                    <v-card-text>

                    <v-flex>

                                <v-menu
                                ref="menuFecha1"
                                v-model="menuFecha1"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                            
                                offset-y
                                min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="fecha_inicio"
                                        label="Fecha inicio"
                                        prepend-icon="event"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="fecha_inicio" locale="es"
                                    no-title  @input="menuFecha1 = false" scrollable>
                                    </v-date-picker>
                                </v-menu>
                        </v-flex>

                    <v-flex>
                            <v-menu
                            ref="menuFecha2"
                            v-model="menuFecha2"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                        
                            offset-y
                            min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="fecha_fin"
                                    label="Fecha fin"
                                    prepend-icon="event"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="fecha_fin" locale="es"
                                no-title  @input="menuFecha2 = false" scrollable>
                                </v-date-picker>
                            </v-menu>

                        </v-flex>

                    
                            <v-flex justify-center d-flex>
                            
                                <v-btn color="success" dark @click="Procesar()" style="margin-right:10px;">Generar</v-btn>
                                <v-btn  @click="dialogHistorial=false" text color="indigo" style="margin-right:10px;">Cancelar</v-btn>

                        </v-flex>
            
                    </v-card-text>
                
                </v-card>
            </v-dialog>


            </v-toolbar>
            </template>


         

             <template v-slot:[`item.flota`]="{ item }">
            <span>{{ item.id_vehiculo.flota }}</span>            
            </template>


    
            
            <template v-slot:[`item.fecha`]="{ item }">
              <span>{{ getDate(item.fecha) }}</span>

              <v-icon></v-icon>
            </template>


            <template v-slot:[`item.ubicacion`]="{ item }">
              <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
            </template>


            <template v-slot:[`item.observacion`]="{ item }">
              <span>{{item.observacion}} / {{ item.editado }}</span>
            </template>



            <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
                edit
            </v-icon>
            
            </template>
            <template v-slot:no-data>
            <v-btn color="primary" @click="listar()" >
                Reset
            </v-btn>
            </template>
        </v-data-table>


  

     </v-row>



 </v-layout>

</template>


<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";
export default {
    data () {
      return {
         arrayAlertas:[],
         column_excel:[
                {label: "vehiculo",  field: "id_vehiculo.nombre"},
                 {label: "placa",  field: "id_vehiculo.placa"},
                 {label: "flota",  field: "flota"},
                 {label: "evento",  field: "evento"},
                 {label: "fecha",  field: "fecha"},
                 {label: "direccion",  field: "direccion"},
                 {label: "latitud",  field: "latitud"},
                 {label: "longitud",  field: "longitud"},
                 {label: "observacion",  field: "observacion"},
                 {label:"editado", field:"editado"}
         ],
         search: '',
         headers: [
            { text: 'Nombre', value: 'id_vehiculo.nombre' },
            { text: 'Placa', value: 'id_vehiculo.placa' },
            { text: 'Flota', value: 'flota' },
            { text: 'Evento', value: 'evento' },
            { text: 'Fecha', value: 'fecha' },
            { text: 'Direccion', value: 'direccion' },
            { text: 'Ubicacion', value: 'ubicacion' },
            { text: 'Observacion', value: 'observacion' },
            { text:'Acciones', value:'actions'}
         ],
         dialogTabla:false,
         editedIndex: -1,

        editedItem: {
                        id_vehiculo:'',
                        fecha:'',
                        evento:'',
                        grupo:'',
                        latitud:'',
                        longitud:'',
                        direccion:'',
                        velocidad:'',
                        curso:'',
                        nemotecnico:'',
                        tipo:'',
                        activo:1,
                        creado:'',
                        observacion:'',
                        editado:''
                    },
        defaultItem: {
                        id_vehiculo:'',
                        fecha:'',
                        evento:'',
                        grupo:'',
                        latitud:'',
                        longitud:'',
                        direccion:'',
                        velocidad:'',
                        curso:'',
                        nemotecnico:'',
                        tipo:'',
                        activo:1,
                        creado:'',
                        observacion:'',
                        editado:''
        },

         selected:[],
          headersSelected:[
            { text: 'Vehiculo', value: 'vehiculo' },
            { text: 'Placa', value: 'placa' },
            { text: 'Flota', value: 'flota' },
            { text: 'Evento', value: 'evento' },
            { text: 'Fecha', value: 'fecha' }
            ],
          itemSelected:[],
          singleSelect:false,
          checkboxAll:false,

          switch1: true,
          LabelSwitch:'Pendientes',

          dialogHistorial:false,
          menuFecha1:false,
          fecha_inicio:new Date().toISOString().substr(0, 10),
          menuFecha2:false,
          fecha_fin:new Date().toISOString().substr(0, 10),
          date: new Date().toISOString().substr(0, 10),

    }
    },
    created () {
       this.listar();
       this.$root.$refs.Home.detenerTimer();
     
    },
     computed:{

       ...mapState(['usuario', 'token']),

        formTitle () {
          return this.editedItem.evento+':'+this.editedItem.id_vehiculo.nombre+'/'+this.getDate(this.editedItem.fecha);
        },


    },watch: {

        dialogTabla (val) {
            val || this.close()
          },

           switch1(val){
              if(val==true){
                this.LabelSwitch='Pendientes';
                this.listar();
              }else{
                this.LabelSwitch='Historial';
                this.arrayAlertas=[];
                this.dialogHistorial=true;

              }
         },

    },
    methods:{

       ...mapMutations(['mostrarLoading','ocultarLoading']),

       
         listar(){

                 this.arrayAlertas=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`alertapend/${this.usuario.id_usr_empresa._id}`,
                    config
                    ).then(function(response){
                        console.log(response);
                        me.arrayAlertas=response.data;
                        me.ocultarLoading();
                    }).catch(function(error){ console.log(error); });

          },

        Procesar(){
              this.arrayAlertas=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.post('listaralertas', 
                           {
                            'id_empresa':this.usuario.id_usr_empresa._id,
                            'desde':this.fecha_inicio,
                            'hasta':this.fecha_fin
                            },
                    config
                    ).then(function(response){
                        console.log(response);
                        me.arrayAlertas=response.data;
                        me.dialogHistorial=false;
                        me.ocultarLoading();
                    }).catch(function(error){ console.log(error); });
        },

        editItem (item) {
              this.editedIndex = this.arrayAlertas.indexOf(item)
              this.editedItem = Object.assign({}, item);


              this.itemSelected=[];

              let me=this;

              console.log('editando datos');

              console.log(item);

              this.arrayAlertas.map(function(x){
                  if(((x.id_vehiculo._id==item.id_vehiculo._id)&&(x.observacion==''))||(x._id==item._id)){

                    if(x._id==item._id){
                      me.selected.push(x);
                    }
                    me.itemSelected.push(x);
                  }
              });



              this.dialogTabla = true;
        },

        close () {
           
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            });
             this.dialogTabla = false
        },

        save () {

         let me=this;

        if (this.editedIndex > -1) {
        
                   //actualizacion de eventos de botones de panicos seleccionados
               if( this.selected.length >0){


                   let config={headers:{token:this.token}};
                  this.mostrarLoading({titulo:'actualizando'});
                  let editado_usuario= me.usuario.nombre_usuario;
                  console.log('cantidad seleccionada:'+this.selected.length);

                  

                 

                        this.selected.map(function(x){

                                let id=x._id;

                                axios.put(`alertas/${id}`,
                                  { 

                                    'observacion': me.editedItem.observacion,
                                    'editado': editado_usuario+', a las: '+me.getDate(new Date())
                                  },
                                  config

                                    ).then(response=>{
                                  
                                    console.log(response);
                                    
                              }).catch(e=>{
                                    console.log(e)
                              });
                        
                        
                        });
                
                            this.close();
                            this.ocultarLoading();
                            this.listar();

                  }else{
                        alert('debes seleccionar registro');
                      }
        
        } 
       
        },

        CheckboxTodos(){

        let me=this;

        if(this.checkboxAll==true){

           this.arrayAlertas.map(function(x){
              if( (x.id_vehiculo._id==me.editedItem.id_vehiculo._id)&&(x.observacion=='') ){
                  me.selected.push(x);
              }
          });


             
        }else{

             let me=this;

            this.selected=[];
            this.selected.push({'_id':this.editedItem._id});

        }


        },

        openGoogleMap(item){
                  const urlSuffix = item.latitud +','+ item.longitud;
                  window.open(
                      "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
                      "_blank"
                  );
        },

        getDate(evaluar) {
          let date = new Date(evaluar),
          year = date.getFullYear(),
          month = (date.getMonth() + 1).toString(),
          formatedMonth = (month.length === 1) ? ("0" + month) : month,
          day = date.getDate().toString(),
          formatedDay = (day.length === 1) ? ("0" + day) : day,
          hour = date.getHours().toString(),
          formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
          minute = date.getMinutes().toString(),
          formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
          second = date.getSeconds().toString(),
          formatedSecond = (second.length === 1) ? ("0" + second) : second;
          return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
        },

  }

  }
</script>